<template>
    <div class="monitor-ycysfbt">
        <div class="back">
            <el-button type="primary" round size="small" @click="toList"
                >列表视图</el-button
            >
        </div>
        <MyMap :mapData="mapData" @initmap="initmap" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import MyMap from "@comp/map/BaseMap.vue";

export default {
    components: {
        MyMap,
    },
    data() {
        return {
            mapData: {
                dxtState: true,
                areaState: true,
                baseMapNum: 0,
                mapTypeBtn: true,
            },
            dataObj: {},
            years: [],
            year: "",
            files: [],
            pointLayer: null,
        };
    },
    mounted() {
        this.getDiseaseList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        toList() {
            this.$router.push({
                name: "monitoringTable",
                params: {
                    ItemID: "702",
                    MC: "病害调查监测工作情况记录",
                },
            });
        },
        // 获得考古项目列表
        async getDiseaseList() {
            let res = await this.GetGLXList({
                itemcode: "702",
                lm: "jcsj",
            });
            this.DiseaseList = res.ResultValue || [];
            this.addMarks();
        },
        initmap(mymap) {
            mymap.setZoom(8);
            this.basicMap = mymap;
            this.pointLayer = L.layerGroup().addTo(this.basicMap);
        },
        addMarks() {
            // this.pointLayer = L.layerGroup().addTo(this.basicMap);
            let icon = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_default.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            this.DiseaseList.forEach((v) => {
                let marker = L.marker([+v.BHWD, +v.BHJD], {
                    icon: icon,
                });
                marker
                    .on("mouseover", (e) => {
                        // eslint-disable-next-line no-undef
                        L.popup({
                            closeOnClick: false,
                            closeButton: false,
                            offset: [0, 5],
                            minWidth: 12,
                        })
                            .setLatLng([v.BHWD, v.BHJD])
                            .setContent(v.BHMC)
                            .openOn(this.basicMap);
                    })
                    .on("mouseout", (e) => {
                        this.basicMap.closePopup();
                    });
                this.pointLayer.addLayer(marker);
            });
        },
    },
};
</script>

<style lang="scss">
.monitor-ycysfbt {
    > div.selector {
        div.el-input.el-input--suffix {
            > input {
                width: 200px;
                border-radius: 0;
                height: 45px;
                font-size: 18px;
                font-family: "MircosoftYaHei Regular";
                color: rgb(51, 51, 51);
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.monitor-ycysfbt {
    position: relative;
    > div.selector {
        left: 45px;
        top: 45px;
        position: absolute;
        z-index: 999;
    }
    > div.download-list {
        left: 45px;
        top: 100px;
        position: absolute;
        z-index: 999;
        width: 200px;
        > div {
            height: 45px;
            line-height: 45px;
            padding: 0 15px 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
            > a {
                display: inline-block;
                padding-left: 25px;
                background-image: url("~@image/working_desk/monitor_data/download.png");
                background-repeat: no-repeat;
                background-position: left center;
                cursor: pointer;
                color: #5ab0f7;
            }
        }
    }
}
.back {
    position: absolute;
    right: 120px;
    top: 10px;
    z-index: 1000;
}
</style>