// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/working_desk/monitor_data/download.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".monitor-ycysfbt[data-v-347f4e4c]{position:relative}.monitor-ycysfbt>div.selector[data-v-347f4e4c]{left:45px;top:45px;position:absolute;z-index:999}.monitor-ycysfbt>div.download-list[data-v-347f4e4c]{left:45px;top:100px;position:absolute;z-index:999;width:200px}.monitor-ycysfbt>div.download-list>div[data-v-347f4e4c]{height:45px;line-height:45px;padding:0 15px 0;background:#fff;display:flex;justify-content:space-between}.monitor-ycysfbt>div.download-list>div>a[data-v-347f4e4c]{display:inline-block;padding-left:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:0;cursor:pointer;color:#5ab0f7}.back[data-v-347f4e4c]{position:absolute;right:120px;top:10px;z-index:1000}", ""]);
// Exports
module.exports = exports;
